import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import type { InferGetStaticPropsType } from 'next'
import type { AppProps } from 'next/app'
import { Inter } from 'next/font/google'
import localFont from 'next/font/local'
import Script from 'next/script'
import { ToastContainer } from 'react-toastify'
import { useMedia } from 'react-use'
import { SharedLoadingIndicatorContextProvider, SharedProgressLoadingIndicator } from 'shared-loading-indicator'
import { PageNavigationLoadingTracker } from '../components/PageNavigationLoadingTracker'
import '../styles/globals.sass'
import { api } from '../utilities/api'
import type { getStaticProps } from './[[...path]]'

const inter = Inter({
	subsets: ['latin', 'latin-ext'],
	weight: ['300', '400', '600', '700'],
	style: 'normal',
})

const albra = localFont({
	src: [
		{
			path: '../styles/fonts/Albra-Semi.woff2',
			weight: '600',
			style: 'normal',
		},
		{
			path: '../styles/fonts/Albra-Bold.woff2',
			weight: '700',
			style: 'normal',
		},
	],
	display: 'swap',
})

function MyApp({
	Component,
	pageProps,
}: Omit<AppProps, 'pageProps'> & { pageProps: InferGetStaticPropsType<typeof getStaticProps> }) {
	const theme = useMedia('(prefers-color-scheme: dark)', false) ? 'dark' : 'light'

	return (
		<>
			<Script id="google-tag-manager" strategy="afterInteractive">
				{`
					// This loads only on first load/hard reload
					(function(){
						window.dataLayer = window.dataLayer || []
						window.dataLayer.push({
							page: ${JSON.stringify({
								virtual: false,
								...pageProps.pageDataLayer,
							})},
							_clear: true,
						})
					})();

					(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
					new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
					'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
					})(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');
				`}
			</Script>

			<SharedLoadingIndicatorContextProvider>
				<PageNavigationLoadingTracker />
				<SharedProgressLoadingIndicator />
				<ToastContainer theme={theme} />
				<style jsx global>{`
					:root {
						--font-inter: ${inter.style.fontFamily};
						--font-albra: ${albra.style.fontFamily};
						--font-body: var(--font-inter);
						--font-headings: var(--font-albra);
					}
					html {
						font-family: ${inter.style.fontFamily};
					}
				`}</style>
				<Component {...pageProps} />
				<ReactQueryDevtools />
			</SharedLoadingIndicatorContextProvider>
		</>
	)
}

export default api.withTRPC(MyApp)
